<template>
    <div class="allHead">
    <div class="Head">
        <div class="content">
            <!-- logo -->
            <div class="logo" @click="gohome">
                <a><img src="@/assets/logo.png" alt=""></a>
            </div>
            <div class="head_right">
                <div class="topTab" v-if="fromType == 'Home'">
                    <div class="topTab_item" v-for="(item,index) in HomeTba" :key="index" :class="{active : activeBtn === index}" 
                        @click="goAnchor('anchor-'+ (item == '直播课程' ? 0 : (item == '总课程表' ? 1 : 2)),index)">{{item}}</div>
                    <div class="topTab_item" @click="GoMyFiles">我的刷题报告</div>
                </div>
                <div class="home" v-else><a @click="gohome">课堂首页</a><Divider type="vertical"/></div>
                <!-- 切换班级 -->
                <div class="classlist">
                    <span>切换班级: </span>
                    <Select v-model="courseId" style="width: 220px;" @on-change="SelectClass">
                        <Option v-for="item in CourseList" :value="item.courseId" :key="item.courseId">{{item.title}}</Option>
                    </Select>
                </div>
                <div class="login">
                    <!-- 登录 -->
                    <div class="Nologin" @click="ClickLogin" v-if="!token"><a>点击登录</a></div>
                    <!-- 已登录 -->
                    <div class="Userlogin" v-if="token">
                        <Dropdown @on-click="ClickUser">
                            <a href="javascript:void(0)">
                                {{UserName}}
                            </a>
                            <DropdownMenu slot="list">
                                <DropdownItem name="MyFiles">刷题报告</DropdownItem>
                                <DropdownItem divided name="Review">题目回顾</DropdownItem>
                                <DropdownItem divided name="logout">退出登录</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <Modal v-model="LoginModal" footer-hide :closable="false" width="435" class-name="LoginModal" :mask-closable="!loggingIn">
                <div class="loginContent">
                    <div v-if="!loggingIn&&!isReg">
                        <!-- 分页 -->
		                <div class="tabs">
		                	<a class="tab" v-for="(tabitem,tabindex) in tabs" :key="tabindex" @click="tabchange(tabindex)">
		                		<div :class="{active:count == tabindex}" class="tab1">{{tabitem}}</div>
		                		<div :class="{tabline:count == tabindex}"></div>
		                	</a>
		                </div>
                        <div class="wxlogin" v-show="btnnum == 0">
                            <div class="null"></div>
                            <!-- 微信登录 -->
                            <div id="weixin"></div>
                        </div>
                        <div class="phonelogin" v-show="btnnum == 1">
                            <!-- <p @click="gologin">Content of dialog</p> -->
                            <Login @on-submit="handleSubmit">
                                <Mobile name="mobile" />
                                <Captcha name="captcha" :field="['mobile']" @on-get-captcha="handleGetCaptcha" />
                                <Submit  class="loginbtn"/>
                            </Login>
                        </div>
                    </div>
                    <!-- 未注册用户手机号注册 -->
                    <div v-if="!loggingIn&&isReg">
                        <div class="phonelogin">
                            <p class="isReg_tit">您尚未注册过账号，请通过手机号验证码进行注册</p>
                            <Login @on-submit="handleSubmitRegister">
                                <Mobile name="mobile" />
                                <Captcha name="captcha" :field="['mobile']" @on-get-captcha="handleGetCaptcha" />
                                <Submit class="loginbtn">确认注册</Submit>
                            </Login>
                        </div>
                    </div>
                    <div class="loginclass" v-if="loggingIn">
                        <p class="loginclass_tit">请选择你要进入的班级</p>
                        <div class="loginclass_Content">
                            <a v-for="(item,index) in CourseList" :key="item.courseId" 
                            :class="{active_class:count_class == item.courseId}"
                            class="loginclass_item"
                            @click="checked_class(item.courseId)"
                            >{{item.title}}</a>
                        </div>
                        <Button type="primary" class="studyBtn" @click="studyBtn">开始学习</Button>
                    </div>
                </div>
            </Modal>
        </div>
    </div>
    </div>
</template>

<script>
import { CourseList, GetUserInfo, LastCourse, SmsCode, PhoneLogin, LoginWx, RegWx} from "@/api/Home";
export default {
    name: 'Head',
    props: {
        fromType: String,
        activeBtn: Number,
        ishome: Boolean,
        HomeTba: Array
    },
    components: {},
    data () {
        return {
            // 班级列表
            CourseList:[],
            // 选中班级ID
            courseId: "",
            // 登录Modal
            LoginModal: false,
            token: localStorage.token,
            // 用户名
            UserName: "",
            // 分页
            btnnum: 0,
            tabs: ["微信登录", "手机号登录"],
            count: "",
            // 登录之后
            loggingIn: false,
            // 登录之后选中班级id
            count_class: "",
            isReg: false,
            openid: '',
        }
    },
    created () {
        // 判断是否是微信登录过来的
        if(this.$route.query.code){
            this.WXlogin()
        }
    },
    mounted () {
        this.getCourseList()
        if(this.token){
            this.GetUserInfo()
        }else{
            // 微信登录二维码
            var obj = new WxLogin({
                id: "weixin",
                appid: "wxbc283b46152cf832",
                scope: "snsapi_login",
                // 扫码成功后 跳转的地址
                redirect_uri: encodeURIComponent("https://web.zhiyugk.com"),
                href: '',
                state: new Date().getTime()
            });
        }
    },
    methods: {
        // 获取微信登录的code进行登录
        WXlogin(){
            LoginWx({code:this.$route.query.code}).then((res) => {
                if(res.ret == 1){
                    // 清除code
                    this.$router.push({ query: {} });
                    // 用户已注册
                    if(res.data.isReg == 1){
                        localStorage.setItem('token','Bearer '+res.data.token);
                        // 登录后选择班级
                        this.loggingIn = true
                        this.LoginModal = true
                        this.getCourseList()
                    }else if(res.data.isReg == 0){
                        // 用户未注册
                        this.isReg = true
                        this.LoginModal = true
                        this.openid = res.data.openid
                    }
                }else{
                    console.log(res.msg)
                }
            });
        },

        //返回首页
        gohome(){
            // 是否是首页
            if(this.ishome){
                this.$router.go(0);
            }else{
                this.$router.push({path: '/'})
            }
        },
        
        // 获取选择班型列表
        getCourseList() {
            CourseList().then((res) => {
                if(res.ret == 1){
                    // 选中班级ID
                    this.courseId = res.data.lastCourseId
                    localStorage.setItem("courseId", res.data.lastCourseId);
                    // 班级列表
                    this.CourseList = res.data.list
                    // 获取到班级id刷新页面
                    this.$emit("refresh");
                }else{
                    console.log(res.msg)
                }
            });
        },

        // 提交最后选择的班级
        SelectClass(e){
            LastCourse({courseId:e}).then((res) => {
                if(res.ret == 1){
                    // 返回首页
                    this.gohome()
                }else{
                    console.log(res.msg)
                }
            });
        },

        // 点击登录打开登录窗口
        ClickLogin(){
            this.LoginModal = true
        },

        // 获取个人信息数据
        GetUserInfo(){
            GetUserInfo("").then((res) => {
                if(res.ret == 1){
                    // 用户名
                    this.UserName = res.data.realName? res.data.realName : (res.data.nickname? res.data.nickname : '学员')
                    localStorage.setItem('UserName', res.data.realName? res.data.realName : (res.data.nickname? res.data.nickname : '学员'));
                    // 用户id
                    localStorage.setItem('userId', res.data.userId);
                }else{
                    console.log(res.msg)
                }
            });
        },

        // 点击用户选项列表
        ClickUser(e){
            if(e == "MyFiles"){
                // 去刷题报告
                this.$router.push({path: '/MyFiles'})
            }else if(e == "Review"){
                // 题目回顾
                this.$router.push({path: '/Review'})
            }else if(e == "logout"){
                // 退出登录
                localStorage.clear()
                this.gohome()
            }
        },

        // 去刷题报告
        GoMyFiles(){
            this.$router.push({path: '/MyFiles'})
        },

        //tab切换
		tabchange(e) {
			this.count = e
			this.btnnum = e
		},

        // 手机登录注册
        handleSubmit (valid, { mobile, captcha }) {
            if (valid) {
                let logindata = {
                    phone: mobile,
                    secretType: "smsCode",
                    secret: captcha
                }
                PhoneLogin(logindata).then((res) => {
                    if(res.ret == 1){
                        localStorage.setItem('token','Bearer '+res.data.token);
                        // 登录后选择班级
                        this.loggingIn = true
                        this.getCourseList()
                    }else{
                        console.log(res.msg)
                    }
                })
            }
        },

        // pc端微信注册
        handleSubmitRegister (valid, { mobile, captcha }) {
            if (valid) {
                let Regdata = {
                    phone: mobile,
                    openid: this.openid,
                    smsCode: captcha
                }
                RegWx(Regdata).then((res) => {
                    if(res.ret == 1){
                        localStorage.setItem('token','Bearer '+res.data.token);
                        // 登录后选择班级
                        this.isReg = false
                        this.loggingIn = true
                        this.getCourseList()
                    }else{
                        console.log(res.msg)
                    }
                })
            }
        },

        // 获取验证码
        handleGetCaptcha (value, values) {
            let SmsCodeData = {
                phone: values.mobile,
                type: "forLogin"
            }
            SmsCode(SmsCodeData).then((res) => {
                if(res.ret == 1){
                    this.$Message.success('验证码发送成功');
                }else{
                    console.log(res.msg)
                }
            })
        },

        // 登录后选择班级
        checked_class(id){
            this.count_class = id
        },

        // 开始学习
        studyBtn(){
            if(this.count_class === ""){
                this.$Message.warning('请选择你要进入的班级');
                return
            }
            this.SelectClass(this.count_class)
        },

        // 顶部Tab锚点跳转
        goAnchor(selector, index) {
            this.$emit('goAnchor',selector,index)
        },
    },
}
</script> 

<style lang='less'>
.allHead{
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 99;
}
    .Head{
        min-width: 1200px;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        background-color: #fff;
        box-shadow: 0px 12px 20px 5px rgba(224, 224, 224, 0.42);
        .content{
            display: flex;
            justify-content: space-between;
            width: 1200px;
            height: 50px;
            .logo{
                padding: 6px;
                img{
                    width: 70px;
                    height: 37px;
                }
            }
            .head_right{
                display: flex;
                align-items: center;
                .topTab{
                    display: flex;
                    .topTab_item{
                        height: 50px;
                        line-height: 50px;
                        margin-right: 40px;
                        font-size: 16px;
                        font-weight: 400;
                        color: #000000;
                        cursor: pointer;
                    }
                    .topTab_item:last-child{
                        margin-right: 25px;
                    }
                    .active{
                        color: #D13F3F;
                        border-bottom: 3px solid #FB7878;
                    }
                }
                .home{
                    a{
                        font-size: 16px;
                        font-weight: bold;
                        color: #000000;
                        margin-right: 15px;
                    }
                }
                .classlist{
                    margin-left: 15px;
                    display: flex;
                    align-items: center;
                    /deep/.ivu-select-selection{
                        color: #000000;
                    }
                    span{
                        margin-right: 10px;
                    }
                }
                .login{
                    margin-left: 40px;
                    .Nologin{
                        a{
                            font-size: 12px;
                            font-weight: 400;
                            text-decoration: underline;
                            color: #D13F3F;
                        }
                    }
                    .Userlogin{
                        a{
                            font-size: 12px;
                            font-weight: 400;
                            color: #D13F3F;
                            text-decoration: underline;
                        }
                        a:hover{
                            color: #FB7878;
                        }
                    }
                }

            }
        }
    }
    .LoginModal{
        .ivu-modal{
            background: #FFFFFF;
            border: none;
            border-radius: 12px;
            height: 580px;
            .ivu-modal-content{
                border-radius: 12px;
                width: 100%;
                height: 100%;
                background: url("../../assets/loginbg.png") no-repeat;
                // box-shadow: 0px 5px 5px 0px #EEEEEE;
                .loginContent{
                    width: 329px;
                    background-color: #FFFFFF;
                    margin: 107px auto 0;
                    border-radius: 4px;
                    //分页
                    .tabs {
                    	display: flex;
                        justify-content: space-between;
                    	align-items: flex-end;
                    	padding: 20px 40px 0;
                    	.tab {
                            width: 100px;
                    		font-size: 20px;
                    		font-family: PingFang SC;
                            font-weight: 500;
                            color: #333333;
                    		position: relative;
                            text-align: center;
                    		.tabline {
                    			width: 44px;
                    			height: 10px;
                                border-radius: 2px;
                    			background-color: #FB7878;
                    			position: absolute;
                    			bottom: 1px;
                    			left: 30%;
                    		}
                    		.tab1 {
                    			bottom: 0;
                    		}
                    	}
                    	.active {
                    		position: relative;
                    		z-index: 2;
                    	}
                    }
                    // 微信登录
                    .wxlogin{
                        // margin-top: 50px;
                        text-align: center;
                        position: relative;
                        .null{
                            width: 100%;
                            height: 45px;
                            background-color: #fff;
                            position: absolute;
                            top: 0;
                        }
                        img{
                            width: 237px;
                            height: 238px;
                        }
                        p{
                            margin-top: 10px;
                            font-size: 14px;
                            font-family: PingFang SC;
                            font-weight: 500;
                            color: #333333;
                        }
                    }
                    // 手机号登录
                    .phonelogin{
                        // width: 277px;
                        // margin: 0 auto;
                        margin-top: 50px;
                        padding: 26px;
                        span{
                            font-size: 12px;
                        }
                        /deep/.ivu-btn-long{
                            text-align: center;
                            font-size: 12px;
                        }
                        // /deep/.ivu-btn-primary{
                        //     margin-top: 60px;
                        // }
                        input{
                            font-size: 14px;
                        }
                        .loginbtn{
                            margin-top: 95px;
                        }
                        .isReg_tit{
                            margin-bottom: 40px;
                            // font-size: 12px;
                        }
                    }
                    .loginclass{
                        .loginclass_tit{
                            text-align: center;
                            padding: 20px 0;
                            font-size: 20px;
                            font-family: PingFang SC;
                            font-weight: 500;
                            color: #333333;
                        }
                        .loginclass_Content{
                            width: 301px;
                            height: 239px;
                            background: #F4F4F4;
                            border-radius: 4px;
                            margin: 0 auto;
                            padding: 20px 0 10px 0;
                            overflow: hidden;
                            overflow-y: auto;
                            .loginclass_item{
                                display: block;
                                text-align: center;
                                width: 241px;
                                padding: 9px;
                                border-radius: 4px;
                                background-color: #fff;
                                font-size: 14px;
                                font-family: PingFang SC;
                                font-weight: 500;
                                color: #000000;
                                margin: 0 auto 10px;
                            }
                            .active_class{
                                background: #FB7878;
                                color: #FFFFFF;
                            }
                        }
                        .loginclass_Content::-webkit-scrollbar {
                          width: 5px;
                          height: 1px;
                        }
                        .loginclass_Content::-webkit-scrollbar-thumb {
                          border-radius: 10px;
                          background-color: #fff;
                          background-image: -webkit-linear-gradient(
                            45deg,
                            rgba(255, 255, 255, 0.2) 25%,
                            transparent 25%,
                            transparent 50%,
                            rgba(255, 255, 255, 0.2) 50%,
                            rgba(255, 255, 255, 0.2) 75%,
                            transparent 75%,
                            transparent
                          );
                        }
                        .loginclass_Content::-webkit-scrollbar-track {
                          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                          background: #ededed;
                          border-radius: 10px;
                        }
                        .studyBtn{
                            width: 277px;
                            height: 40px;
                            margin: 40px 26px 0 26px;
                        }
                    }
                }
            }
        }
    }
</style>
