import request from '@/request';

// 登录获取token
// export function GetToken (data) {
//     return request({
//         url: '/getToken',
//         method: 'post',
//         data
//     });
// }

// 短信验证码发送
export function SmsCode (data) {
    return request({
        url: '/smsCode',
        method: 'post',
        data
    });
}

// 手机登录注册
export function PhoneLogin (data) {
    return request({
        url: '/login',
        method: 'post',
        data
    });
}

// 获取个人信息
export function GetUserInfo (data) {
    return request({
        url: '/v2/user/info?subjectId='+data,
        method: 'get',
    });
}

// 获取选择班型列表
export function CourseList (data) {
    return request({
        url: '/v2/course/list',
        method: 'get',
    });
}

// 提交最后选择的班级
export function LastCourse (data) {
    return request({
        url: '/user/last/course',
        method: 'post',
        data
    });
}

// 提交吐槽反馈
export function UserFeedback (data) {
    return request({
        url: '/user/feedback',
        method: 'post',
        data
    });
}

// 获取更多直播/回放列表
export function GetLiveList (data) {
    return request({
        url: '/course/live/list?courseId='+data.courseId+'&type='+data.type,
        method: 'get',
    });
}

// 获取总课程表
export function TotalSchedule (data) {
    return request({
        url: '/course/subject/list?courseId='+data,
        method: 'get',
    });
}

// 获取班级详情
export function CourseDetail (data) {
    return request({
        url: '/course/detail?courseId='+data,
        method: 'get',
    });
}

// 分页-获取班级试卷列表
export function CoursePapers (data) {
    return request({
        url: '/course/papers?courseId='+data.courseId+'&moduleType='+data.moduleType+'&page='+data.page+'&limit='+data.limit,
        method: 'get',
    });
}

// 微信二维码登录
export function LoginWx (data) {
    return request({
        url: '/web/loginWx',
        method: 'post',
        data
    });
}

// pc端微信注册
export function RegWx (data) {
    return request({
        url: '/web/regWx',
        method: 'post',
        data
    });
}