<template>
    <!-- 联系客服弹窗 -->
    <Modal v-model="Kfmodal" footer-hide :closable="false" width="276">
        <div class="kf_content">
            <div class="ke_QR">
                <img src="@/assets/kefu.jpg" alt="">
            </div>
            <p>扫码就能添加职域客服</p>
            <Button @click="Kfmodal = false">关闭弹窗</Button>
        </div>
    </Modal>
</template>

<script>
export default {
    name: 'Kfmodal',
    props: {},
    components: {},
    data () {
       return {
            // 客服弹窗
            Kfmodal: false,
       }
    },
    created () {
    },
    mounted () {
    },
    methods: {
        Click_kf(){
            this.Kfmodal = true
        }
    },
}
</script> 

<style lang='less' scoped>
    // 联系客服
    .kf_content{
        .ke_QR{
            background-color: #eee;
            padding: 14px;
            margin: 15px 15px 12px 15px;
            img{
                width: 186px;
                height: 186px;
            }
        }
        p{
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #000000;
            text-align: center;
        }
        Button{
            display: block;
            width: 120px;
            height: 40px;
            margin: 30px auto 15px;
            color: #D13F3F;
        }
    }
</style>
