import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 导入 ViewUI 和 iView Pro
import ViewUI from 'view-design';
import iViewPro from '@/libs/iview-pro/iview-pro.min.js';
// 导入 iView 和 iView Pro 的样式
import 'view-design/dist/styles/iview.css';
import '@/libs/iview-pro/iview-pro.css';
import './my-theme/index.less'
// 视频播放器
import VideoPlayer from 'vue-video-player'
window.videojs = VideoPlayer.videojs
require('video.js/dist/lang/zh-CN.js')
Vue.use(VideoPlayer)


Vue.use(ViewUI);
Vue.use(iViewPro);

Vue.config.productionTip = false

router.beforeEach((to,from,next)=>{//beforeEach是router的钩子函数，在进入路由前执行
  if(to.meta.title){//判断是否有标题
      document.title = to.meta.title
  }
  if(to.path === '/'){
      next();
  }else{
      let token = localStorage.token
      // 是否有登录，无则返回首页
      if(token){
          next();
      }else{
          next('/');
      }
  }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
