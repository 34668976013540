import axios from 'axios';

import { Message} from 'view-design';

// 创建一个错误
function errorCreate (msg) {
    const err = new Error(msg);
    errorLog(err);
    throw err;
}

// 记录和显示错误
function errorLog (err) {
    Message.error({
        content: err.message,
        // 接口请求返回错误时，弹窗的持续时间，单位：秒
        duration: 3
    });
}

// 外部config.js配置的api地址
const apiBaseURL = window.g.ApiUrl

// 创建一个 axios 实例
const service = axios.create({
    baseURL: apiBaseURL,
    timeout: 30000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 在请求发送之前做一些处理
        const token = localStorage.token ? localStorage.token : sessionStorage.token;
        if(token){
            // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
            config.headers['Authorization'] = token;
        }
        // 客户端类型
        config.headers['clientType'] = window.g.clientType;
        // 客户端程序版本
        config.headers['clientVersion'] = window.g.clientVersion;
        // 本平台端口
        config.headers['wxMiniName'] = window.g.wxMiniName;
        return config;
    },
    error => {
        // 发送失败
        console.log(error);
        Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    response => {
        // dataAxios 是 axios 返回数据中的 data
        const dataAxios = response;
        // 这个状态码是和后端约定的
        const { code } = dataAxios.data;
        // 根据 code 进行判断
        if (code === undefined) {
            // 如果没有 code 代表这不是项目后端开发的接口
            return dataAxios;
        } else {
            // debugger
            // 有 code 代表这是一个后端接口 可以进行进一步的判断
            switch (code) {
            case 200:
                // [ 示例 ] code === 0 代表没有错误
                dataAxios.data.code = code
                return dataAxios.data;
            case 202:
                // debugger
                // [ 示例 ] 其它和后台约定的 code
                errorCreate(`${dataAxios.data.msg}`);
                break;
            default:
                // 不是正确的 code
                errorCreate(`${dataAxios.data.msg}: ${response.config.url}`);
                break;
            }
        }
    },
    error => {
        if (error && error.response) {
            switch (error.response.status) {
            case 202: error.message = error.response.data.msg || '请求错误'; break;
            case 400: error.message = '服务器出错啦，请稍候重试'; break;
            case 401: error.message = '未授权，请登录'; 
            // 删除token
            localStorage.removeItem("token");
            // 跳转路由
            window.location.href = '/';
            break;
            case 403: error.message = '拒绝访问'; break;
            case 404: error.message = `请求地址出错: ${error.response.config.url}`; break;
            case 408: error.message = '请求超时'; break;
            case 429: error.message = '服务器繁忙，请稍等再试'; break;
            case 500: error.message = '服务器出错啦，请联系管理员'; break;
            case 501: error.message = '服务未实现'; break;
            case 502: error.message = '网关错误'; break;
            case 503: error.message = '服务不可用'; break;
            case 504: error.message = '网关超时'; break;
            case 505: error.message = 'HTTP版本不受支持'; break;
            default: break;
            }
        }
        errorLog(error);
        return Promise.reject(error);
    }
);

export default service;
