import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      //上传图片地址
      uploadImageUrl: `${window.g.ApiUrl}/upload/image`,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
