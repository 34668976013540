<template>
    <div class="iCopyright">
        <div class="content">
            <div class="copyright">
                <div class="provide">学习平台由  职域公考  提供</div>
                <div class="copyright_num">ICP备案号：粤ICP备20067236号-2</div>
            </div>
            <div class="link">
                <div class="link_left">
                    <p class="link_tit">快速入口</p>
                    <p><a href="http://www.zhiyugk.com/" target="_blank">公司介绍</a></p>
                    <p><a href="http://www.zhiyugk.com/" target="_blank">限免课程</a></p>
                </div>
                <div class="link_right">
                    <p class="link_tit">其他业务</p>
                    <p><a href="http://www.ty-job.com/" target="_blank">天域人力</a></p>
                    <p><a href="https://www.zhiyuxt.com/" target="_blank">职域学堂</a></p>
                </div>
            </div>
            <div class="QR">
                <div class="QR_item">
                    <img src="@/assets/btzWxapp.jpg" alt="">
                    <p>百题斩小程序</p>
                </div>
                <div class="QR_item">
                    <img src="@/assets/wxapp.jpg" alt="">
                    <p>职域公考小程序</p>
                </div>
                <div class="QR_item">
                    <img src="@/assets/kefu.jpg" alt="">
                    <p>添加联系客服</p>
                </div>
                <div class="QR_item">
                    <img src="@/assets/Official.jpg" alt="">
                    <p>扫码关注公众号</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'iCopyright',
    props: {},
    components: {},
    data () {
      return {}
    },
    created () {
    },
    mounted () {
    },
    methods: {},
}
</script> 

<style lang='less' scoped>
    .iCopyright{
        min-width: 1200px;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        background-color: #222222;
        .content{
            width: 1200px;
            height: 141px;
            color: #fff;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            .copyright{
                padding-top: 70px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                text-align: left;
                .provide{
                    margin-bottom: 8px;
                }
            }
            .link{
                margin-top: 40px;
                display: flex;
                p{
                    margin-bottom: 8px;
                    a{
                        color: #fff;
                    }
                    a:hover{
                        color: #D13F3F;
                        text-decoration: underline;
                    }
                }
                .link_left{
                    margin-right: 46px;
                }
                .link_tit{
                    font-size: 16px;
                }
            }
            .QR{
                display: flex;
                margin-top: 21px;
                font-size: 12px;
                .QR_item{
                    margin-right: 40px;
                    img{
                        width: 80px;
                        height: 80px;
                    }
                }
                .QR_item:last-child{
                    margin-right: 0;
                }
            }
        }
    }
</style>


