import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '首页 - 职域公考'
    },
    component: Home
  },
  {
    path: '/MyFiles',
    name: 'MyFiles',
    meta: {
      title: '刷题报告 - 职域公考'
    },
    component: () => import('@/views/MyFiles')
  },
  {
    path: '/Review',
    name: 'Review',
    meta: {
      title: '题目回顾 - 职域公考'
    },
    component: () => import('@/views/Review')
  },
  {
    path: '/CollectTopic',
    name: 'CollectTopic',
    meta: {
      title: '收藏题目 - 职域公考'
    },
    component: () => import('@/views/Review/CollectTopic')
  },
  {
    path: '/WrongTopic',
    name: 'WrongTopic',
    meta: {
      title: '错题本 - 职域公考'
    },
    component: () => import('@/views/Review/WrongTopic')
  },
  {
    path: '/QuestionPapers',
    name: 'QuestionPapers',
    meta: {
      title: '试卷 - 职域公考'
    },
    component: () => import('@/views/QuestionPapers')
  },
  {
    path: '/VideoCourse',
    name: 'VideoCourse',
    meta: {
      title: '视频课程 - 职域公考'
    },
    component: () => import('@/views/Course/VideoCourse.vue')
  },
  {
    path: '/LiveCourse',
    name: 'LiveCourse',
    meta: {
      title: '直播课程 - 职域公考'
    },
    component: () => import('@/views/Course/LiveCourse.vue')
  },
  {
    path: '/PlaybackCourse',
    name: 'PlaybackCourse',
    meta: {
      title: '直播回放 - 职域公考'
    },
    component: () => import('@/views/Course/PlaybackCourse.vue')
  },
  {
    path: '*',
    name: '404',
    meta: {
        title: '404'
    },
    component: () => import('@/views/system/error/404')
  }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) { // 解决vue页面之间跳转，页面不是在顶部的问题
      return { x: 0, y: 0 }
    },
})
const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

export default router
