<template>
    <!-- 我要吐槽弹窗 -->
    <Modal v-model="Dissmodal" footer-hide :closable="false" width="688">
        <div class="Diss_content">
            <p class="Diss_tit">意见反馈</p>
            <Input v-model="Dissvalue" maxlength="150" show-word-limit type="textarea" :autosize="{minRows: 6,maxRows:6}" placeholder="有意见直接吐槽，不用客气" class="Diss_Input" />
            <!-- 上传图片 -->
            <div class="uploading">
                <div class="demo-upload-list" v-for="(item,index) in uploadList">
                    <template v-if="item.status === 'finished'">
                        <img :src="item.url">
                        <div class="demo-upload-list-cover">
                            <Upload
                                ref="newupload"
                                :show-upload-list="false"
                                :on-success="alterhandleSuccess"
                                :on-error="handleError"
                                :format="['jpg','jpeg','png']"
                                :max-size="2048"
                                :on-format-error="handleFormatError"
                                :on-exceeded-size="handleMaxSize"
                                :action="$store.state.uploadImageUrl"
                                :headers="{Authorization: token,clientType: clientType,clientVersion: clientVersion}"
                                style="display: inline-block;"
                                >
                                <Icon type="md-create" @click="getindex(index)"></Icon>
                            </Upload>
                            <Icon type="ios-trash-outline" @click.native="handleRemove(item)"></Icon>
                        </div>
                    </template>
                    <template v-else>
                        <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
                    </template>
                </div>
                <Upload
                    ref="upload"
                    :show-upload-list="false"
                    :on-success="handleSuccess"
                    :on-error="handleError"
                    :format="['jpg','jpeg','png']"
                    :max-size="2048"
                    :on-format-error="handleFormatError"
                    :on-exceeded-size="handleMaxSize"
                    multiple
                    type="drag"
                    :action="$store.state.uploadImageUrl"
                    :headers="{Authorization: this.token,clientType: this.clientType,clientVersion: this.clientVersion}"
                    style="display: inline-block;width:100px;"
                    v-show="uploadList.length<3"
                    >
                    <div style="width: 100px;height:100px;line-height: 100px;">
                        <Icon type="ios-camera" size="20"></Icon>
                    </div>
                </Upload>
                <div class="restrict">({{uploadList.length}}/3)</div>
            </div>
            <div class="footer_but">
                <Button type="primary" @click="submit">确定提交</Button>
                <Button @click="Dissmodal = false">关闭弹窗</Button>
            </div>
        </div>
    </Modal>
</template>

<script>
import { UserFeedback } from "@/api/Home";
export default {
    name: 'Dissmodal',
    props: {
        title: String
    },
    components: {},
    data () {
       return {
           token: localStorage.token,
           // 客户端类型
           clientType: window.g.clientType,
           // 客户端程序版本
           clientVersion: window.g.clientVersion,
           // 我要吐槽弹窗
            Dissmodal: false,
            // 意见反馈输入内容
            Dissvalue: "",
            imgName: '',
            uploadList: [],
            alterindex: 0
       }
    },
    created () {
    },
    mounted () {
        this.uploadList = this.$refs.upload.fileList;
    },
    methods: {
        // 点击我要吐槽弹窗
        ClickDissmodal(){
            this.Dissmodal = true
        },
        // 删除图片
        handleRemove (file) {
            const fileList = this.$refs.upload.fileList;
            this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
        },
        // 上传成功时
        handleSuccess (res, file) {
            file.url = res.data.url;
            file.name = res.data.oldName;
        },
        // 获取修改图片的index
        getindex(index){
            this.alterindex = index
        },
        // 上传成功时
        alterhandleSuccess (res, file) {
            file.url = res.data.url;
            file.name = res.data.oldName;
            this.$refs.upload.fileList.splice(this.alterindex, 1,file);
        },
        handleError(error, file, fileList) {
          this.$Message.error("图片上传失败！");
        },
        // 图片格式错误
        handleFormatError (file) {
            this.$Message.error('图片格式不正确，请重新上传');
        },
        // 图片超出大小
        handleMaxSize (file) {
            this.$Message.error('图片大小不超过2M，请重新上传');
        },

        // 确定提交
        submit(){
            if(this.Dissvalue == ""){
                this.$Message.error('请输入内容，再进行提交！');
                return
            }
            let fileList = this.$refs.upload.fileList;
            let images = []
            fileList.map((item,index)=>{
                images.push(item.url)
            })
            let Feedback = {
                title: this.title,
                content: this.Dissvalue,
                images: images.join(",")
            }
            UserFeedback(Feedback).then((res) => {
                if(res.ret == 1){
                    this.$Message.success('您的意见已提交');
                    this.Dissmodal = false
                    this.Dissvalue = "",
                    this.$refs.upload.fileList.splice(0, 3);
                }else{
                    console.log(res.msg);
                }
            });
            
        }
    },
}
</script> 

<style lang='less' scoped>
    // 我要吐槽弹窗
    .Diss_content{
        .Diss_tit{
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #000000;
        }
        .Diss_Input{
            width: 100%;
            margin-top: 20px;
            /deep/.ivu-input{
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #333333;
                background-color: #eee;
                border: none
            }
            /deep/.ivu-input-word-count{
                background-color: #eee;
            }
        }
        .uploading{
            margin-top: 20px;
            display: flex;
            align-items: flex-end;
            .demo-upload-list{
                display: inline-block;
                width: 100px;
                height: 100px;
                text-align: center;
                line-height: 100px;
                border: 1px solid transparent;
                border-radius: 4px;
                overflow: hidden;
                background: #fff;
                position: relative;
                box-shadow: 0 1px 1px rgba(0,0,0,.2);
                margin-right: 4px;
            }
            .demo-upload-list img{
                width: 100%;
                height: 100%;
            }
            .demo-upload-list-cover{
                display: none;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgba(0,0,0,.6);
            }
            .demo-upload-list:hover .demo-upload-list-cover{
                display: block;
            }
            .demo-upload-list-cover i{
                color: #fff;
                font-size: 20px;
                cursor: pointer;
                margin: 0 10px;
            }
            .restrict{
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #999999;
                margin-left: 10px;
            }
        }
        .footer_but{
            margin-top: 40px;
            display: flex;
            padding: 0 204px;
            justify-content: space-between;
        }
    }
</style>
