<template>
    <div class="Home">
        <div class="wrap">
            <!-- 页头 -->
            <pageHEAD ref="pageHead" @refresh="refresh" @goAnchor="goAnchor" :activeBtn="activeBtn" :ishome='true'
            :fromType="fromType" :HomeTba="HomeTba"
            />
            <div class="middle">
                <div class="content">
                    <!-- 全部内容 -->
                    <div class="allcontent" v-if="!isallEllipsis">
                        <!-- 直播课程 -->
                        <div class="LiveCourseS anchor-0" v-if="liveNum != 0">
                            <div class="LiveCourseS_content">
                                <div class="LiveCourseS_top">
                                    <img src="@/assets/live.png" alt="" class="liveLabel">
                                    <div class="lastStudy" v-if="liveNum != 0">
                                        <div class="LastTime" v-if="token&&lastStudy.length != 0">
                                            <img src="@/assets/play.png" alt="">
                                            <div class="LastTime_tit">上次播放到：{{lastStudy.lessonTitle}}</div>
                                            <Button type="primary" shape="circle" class="LastTime_btn" @click="ClicklastStudy">继续播放</Button>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="LiveList.length != 0" class="Live_content">
                                    <!-- 直播中 -->
                                    <div class="player" v-if="LiveList[0].extendData.state == 5">
                                        <div class="playerContent">
                                            <div id="live-self-player" class="bjy-player-teacher"></div>
                                            <div id="whiteboard">
                                                <div class="placeholder"></div>
                                                <div class="playmask"></div>
                                                <div class="liveroom" @click="Tolive(1)">点击进入直播间</div> 
                                            </div>
                                        </div>
                                        <div class="player_lessonTitle">{{LiveList[0].lessonTitle}}</div>
                                        <div class="player_startTimeStr">
                                            <span>已直播{{Livetime}}</span>
                                        </div>
                                    </div>
                                    <!-- 直播回放 -->
                                    <div class="player" v-else-if="LiveList[0].extendData.state != 5&&RecentPlay != ''">
                                        <div class="playerContent">
                                            <video id="bjy-player-teacher"
                                                class="bjy-player-teacher"
                                                x5-playsinline="true"
                                                x-webkit-airplay="allow"
                                                webkit-playsinline="true"
                                                playsinline="true"
                                                controls
                                                autoplay muted>
                                            </video>
                                            <div class="bjy-playback-whiteboard-wrapper"></div>
                                            <div class="playmask"></div>
                                            <div class="liveroom" @click="Tolive(2)">点击进入直播间</div>
                                        </div>
                                        <div class="player_lessonTitle">{{RecentPlay.lessonTitle}}</div>
                                        <div class="player_startTimeStr">
                                            <Icon type="md-pause" class="playIcon" v-if="isplay" @click="ClickPlay"/>
                                            <Icon type="md-play"  class="playIcon" v-if="!isplay" @click="ClickPlay"/>
                                            <Icon type="md-volume-mute"  class="playIcon" v-if="!ismute" @click="ClickMute"/>
                                            <Icon type="md-volume-off"  class="playIcon" v-if="ismute" @click="ClickMute"/> 
                                            <span>总时长: {{ViodeoDuration}}</span>
                                        </div>            
                                    </div>
                                    <!-- 直播预告 -->
                                    <div class="player" v-else-if="LiveList[0].extendData.state != 5&&RecentPlay == ''&&PreviewImgData != ''">
                                        <div class="playerContent">
                                            <img :src="PreviewImgData.imgUrl" alt="">
                                        </div> 
                                        <div class="player_lessonTitle">{{PreviewImgData.lessonTitle}}</div>
                                        <div class="player_startTimeStr">开播时间:{{PreviewImgData.startTimeStr}}</div>
                                    </div>
                                    <!-- 直播未生成封面 -->
                                    <div class="player" v-else>
                                        <div class="playerContent">
                                            <img :src="LiveList[0].imgUrl" alt="">
                                        </div>
                                        <div class="player_lessonTitle">{{LiveList[0].lessonTitle}}</div>
                                        <div class="player_startTimeStr">开播时间:{{LiveList[0].startTimeStr}}</div>
                                    </div>
                                </div>
                                <div v-if="LiveList.length == 0&&PreviewList.length !=0"  class="Live_content">
                                    <!-- 直播预告 -->
                                    <div class="player">
                                        <div class="playerContent">
                                            <img :src="PreviewImgData.imgUrl" alt="">
                                        </div>
                                        <div class="player_lessonTitle">{{PreviewImgData.lessonTitle}}</div>
                                        <div class="player_startTimeStr">开播时间:{{PreviewImgData.startTimeStr}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="LiveCourseS_list">
                                <!-- 分页 -->
		                        <div class="tabs">
		                        	<a class="tab" v-for="(tabitem,tabindex) in tabs" :key="tabindex" @click="tabchange(tabindex)">
		                        		<div :class="{active:count == tabindex}" class="tab1">{{tabitem}}</div>
		                        		<div :class="{tabline:count == tabindex}"></div>
		                        	</a>
		                        </div>
                                <div class="Live_list" v-show="btnnum == 0">
                                    <div class="Live_Ellipsis" v-if="LiveList.length == 0">
                                        <img src="@/assets/Live_Ellipsis.png" alt="">
                                        <p>直播课程还未开播，去看看直播预告哦</p>
                                    </div>
                                    <div :padding="0" class="Live_list_item" v-for="(item,index) in LiveList" :key="item.courseLessonId" v-if="LiveList.length != 0">
                                        <div class="Live_list_item_details" @click="ClickLive(item)">
                                            <div class="Live_list_item_left">
                                                <img :src="item.imgUrl" alt="">
                                                <div class="shade" v-if="(item.extendData.state == 5&&RecentPlay==''&&item.extendData.webUrl ==LiveList[0].extendData.webUrl) || (RecentPlay!=''&&item.extendData.playBackUrl == RecentPlay.playBackUrl)">
                                                    <img src="@/assets/play.gif" alt="">
                                                </div>
                                            </div>
                                            <div class="Live_list_item_right">
                                                <p :class="{hotLessonTitle: (item.extendData.state == 5&&RecentPlay==''&&item.extendData.webUrl ==LiveList[0].extendData.webUrl) || (RecentPlay!=''&&item.extendData.playBackUrl == RecentPlay.playBackUrl)}">{{item.lessonTitle}}</p>
                                                <div class="Live_list_item_bottom">
                                                    <p>{{item.teacherName}}</p>
                                                    <div class="item_time">
                                                        <p>{{item.startTimeStr}}</p>
                                                        <!-- <img src="@/assets/ifplay.gif" alt="" class="ifplay" v-if="(item.extendData.state == 5&&RecentPlay==''&&item.extendData.webUrl ==LiveList[0].extendData.webUrl) || (RecentPlay!=''&&item.extendData.playBackUrl == RecentPlay.playBackUrl)"> -->
                                                        <span v-if="item.extendData.state == 5" class="advance">正在热播</span>
                                                        <div v-if="item.extendData.state == 10" class="playback">直播已结束</div>
                                                        <div v-if="item.extendData.state == 15" class="playback">直播回放</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="Live_list" v-show="btnnum == 1">
                                    <div class="Live_Ellipsis" v-if="PreviewList.length == 0">
                                        <img src="@/assets/Preview_Ellipsis.png" alt="">
                                        <p>当前没有直播预告</p>
                                    </div>
                                    <div :padding="0" class="Live_list_item" v-for="(item,index) in PreviewList" :key="item.courseLessonId" v-if="PreviewList.length != 0">
                                        <div class="Live_list_item_details" @click="ClickPreview">
                                            <div class="Live_list_item_left">
                                                <img :src="item.imgUrl" alt="">
                                            </div>
                                            <div class="Live_list_item_right">
                                                <p>{{item.lessonTitle}}</p>
                                                <div class="Live_list_item_bottom">
                                                    <p>{{item.teacherName}}</p>
                                                    <div class="item_time">
                                                        <p>{{item.startTimeStr}}</p>
                                                        <span v-if="item.extendData.state == 1" class="advance">直播预告</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 加载中 -->
                                <Spin fix v-if="live_Loading">
                                    <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
                                    <div>Loading</div>
                                </Spin>
                            </div>
                        </div>

                        <!-- 总课程表 -->
                        <div class="TotalSchedule anchor-1" v-if="lessonNum != 0">
                            <div class="TotalSchedule_tit">
                                <img src="@/assets/classSchedule.png" alt="">
                            </div>
                            <div class="TotalSchedule_content">
                                <!-- 科目 -->
		                        <div class="subjects_tabs">
		                        	<a class="tab" v-for="(item,index) in subjectList" :key="item.subjectId" @click="subjectschange(item.chapters,index)">
		                        		<div :class="{active:subjectindex == index}">{{item.subjectTitle}}</div>
		                        	</a>
		                        </div>
                                <!-- 章节 -->
                                <div class="allchapter">
                                    <a class="chapter_item" v-for="(item,index) in chapterList" :key="item.courseChapId"  :class="{active:chapterindex == index}" @click="chapterchange(item.lessons,index)">
                                        <div>{{item.chapTitle}}</div>
                                        <div :class="{tabline:chapterindex == index}"></div>
                                    </a>
                                </div>
                                <!-- 课节 -->
                                <div class="alllessons">
                                    <Card :padding="0" class="lessons_item" v-for="(item,index) in lessonsList" :key="item.courseLessonId">
                                        <a class="lessons_item_details" :class="{hot:item.extendData.state == 5}" @click="lessonschange(item,index)">
                                            <div class="lessons_item_top">
                                                <p>{{item.lessonTitle}}</p>
                                                <div class="information">
                                                    <span class="teacherName" v-if="item.teacherName"><Icon type="md-contact" class="item_Icon"/> {{item.teacherName}}</span>
                                                    <span class="startTimeStr" v-if="item.startTimeStr"><Icon type="ios-time" class="item_Icon"/> {{item.startTimeStr}}</span>
                                                </div>
                                            </div>
                                            <div class="lessons_item_bottom">
                                                <div class="lessons_item_bottom_left">
                                                    <div class="videoTag" v-if="item.typePlay == 3">视频</div>
                                                    <div class="liveIngTag" v-if="item.typePlay == 1&&item.extendData.state == 5" style="color: #FFF;">正在热播</div>
                                                    <div class="liveTag" v-if="item.typePlay == 1&&item.extendData.state == 1" style="color: #D13F3F;">预告</div>
                                                    <div class="liveTag" v-if="item.typePlay == 1&&item.extendData.state == 10" style="color: #D13F3F;">直播已结束</div>
                                                    <div class="liveTag" v-if="item.typePlay == 1&&item.extendData.state == 15" style="color: #D13F3F;">直播回放</div>
                                                    <img src="@/assets/ifplay.gif" alt="" class="ifplay" v-if="item.typePlay == 1&&item.extendData.state == 5">
                                                </div>
                                                <div class="alreadyTag" v-if="item.isHadStudy&&!item.isLastStudy">已学</div>
                                                <div class="lastTag" v-if="item.isLastStudy">上次播放</div>
                                            </div>
                                        </a>
                                    </Card>
                                </div>
                                <div class="Lessons_Ellipsis" v-if="lessonsList.length == 0">
                                    <img src="@/assets/Lessons_Ellipsis.png" alt="">
                                    <p>当前章节没有课程，去问问老师是否弄错了？</p>
                                </div>
                                <!-- 加载中 -->
                                <Spin fix v-if="lessons_Loading">
                                    <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
                                    <div>Loading</div>
                                </Spin>
                            </div>
                        </div>

                        <!-- 题库试卷 -->
                        <div class="QuestionTest anchor-2" v-if="paperNum != 0">
                            <div class="QuestionTest_tit">
                                <img src="@/assets/questionBank.png" alt="">
                            </div>
                            <div class="QuestionTest_content">
                                <div class="QuestionTest_left">
                                    <div class="module" v-for="(item,index) in moduleimages" :key="index" @click="ClickModule(index)">
                                        <img :src="item.imgUrl" alt="" class="moduleimg">
                                        <img src="@/assets/checked.png" alt="" class="checked" :class="{active:Moduleindex == index}">
                                        <div :class="{tabline:Moduleindex == index}"></div>
                                        <div class="undone" v-if="index == 1&&toDoMockPaperNum != 0">有{{toDoMockPaperNum}}份未做的试卷</div>
                                    </div>
                                </div>
                                <div class="QuestionTest_right">
                                    <Spin fix v-if="test_Loading">
                                        <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
                                        <div>Loading</div>
                                    </Spin>
                                    <div class="Test_Ellipsis" v-if="testlist.length == 0">
                                        <img src="@/assets/Test_Ellipsis.png" alt="">
                                        <p>当前没有配置试卷，去叫老师布置吧</p>
                                    </div>
                                    <div class="alltestlist" v-else>
                                        <div class="testlist">
                                            <Card :padding="0" class="test_item" v-for="(item,index) in testlist" :key="item.paperId">
                                                <!-- 练习试卷未完成 -->
                                                <div class="item_state" style="background: #E30000;" v-if="item.questionNum != item.doneQuestionNum &&item.execType == 1"></div>
                                                <!-- 练习试卷已完成 -->
                                                <div class="item_state" style="background: #FFB900;" v-if="item.questionNum == item.doneQuestionNum &&item.execType == 1"></div>
                                                <!-- 非练习试卷未完成或进行中 -->
                                                <div class="item_state" style="background: #E30000;" v-if="(item.state == 1 || item.state == 5) &&item.execType != 1"></div>
                                                <!-- 非练习试卷已完成 -->
                                                <div class="item_state" style="background: #FFB900;" v-if="item.state == 10 &&item.execType != 1"></div>
                                                <div class="Practice_tests" @click="ClickPapers(item)">
                                                    <div class="Practice_tests_top" v-if="item.execType == 1 || item.execType == 5">
                                                        <span class="Practice_tit" v-if="item.execType == 1">{{item.paperName}}</span>
                                                        <span v-if="item.execType != 1">{{item.paperName}}</span>
                                                        <Tag color="green" v-if="item.execType == 1">正确率：{{item.accuracy}}%</Tag>
                                                    </div>
                                                    <!-- 模考试卷顶部 -->
                                                    <div class="mock_exam_top" v-if="item.execType == 10">
                                                        <p>{{item.paperName}}</p>
                                                        <div class="details">
                                                            <img src="@/assets/mock_exam_doexercise.png" alt="">
                                                            <span>{{item.questionNum}}题</span>
                                                            <img src="@/assets/mock_exam_point.png" alt="">
                                                            <span>{{item.score}}分</span>
                                                            <img src="@/assets/mock_exam_time.png" alt="">
                                                            <span>{{item.limitTime}}分钟</span>
                                                        </div>
                                                    </div>
                                                    <!-- 练习试卷底部 -->
                                                    <div class="Practice_tests_bottom" v-if="item.execType == 1">
                                                        <div class="doexercise">
                                                            <img src="@/assets/doexercise.png" alt="">
                                                            <span>{{item.doneQuestionNum}}/{{item.questionNum}}</span>
                                                            <span class="everytime">（ 每次做题{{item.exerciseNum}}道 ）</span>
                                                        </div>
                                                        <div class="progress">
                                                            <div class="succeed" :style="'width:'+ item.doneQuestionNum/item.questionNum*100 +'%'" v-if="item.doneQuestionNum/item.questionNum == 1"></div>
                                                            <div class="complete" :style="'width:'+ item.doneQuestionNum/item.questionNum*100 +'%'" v-else></div>
                                                        </div>
                                                    </div>
                                                    <!-- 测试试卷底部 -->
                                                    <div class="tests_bottom" v-if="item.execType == 5">
                                                        <div class="tests_bottom_left">
                                                            <img src="@/assets/point.png" alt="">
                                                            <span>共{{item.questionNum}}题，总分{{item.score}}分</span>
                                                        </div>
                                                        <div class="tests_bottom_right">
                                                            <Tag color="red" v-if="item.state == 1">未完成</Tag>
                                                            <Tag color="gold" v-if="item.state == 5">进行中</Tag>
                                                            <Tag color="green" v-if="item.state == 10">得分：{{item.getScore}}分</Tag>
                                                        </div>
                                                    </div>
                                                    <!-- 模考试卷底部 -->
                                                    <div class="mock_exam_bottom" v-if="item.execType == 10">
                                                        <Tag color="red" v-if="item.state == 1">未完成</Tag>
                                                        <Tag color="gold" v-if="item.state == 5">进行中</Tag>
                                                        <Tag color="green" v-if="item.state == 10">得分：{{item.getScore}}分</Tag>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                        <div class="paging" v-if="pagetotal >9">
                                            <Page :total="pagetotal" :page-size="9" :current="testpage" show-elevator @on-change="currentChange"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 完全缺省 -->
                    <div class="allEllipsis" v-if="isallEllipsis">
                        <img src="@/assets/allEllipsis.png" alt="">
                        <p class="allEllipsis_tit">当前班级没有课程</p>
                        <p class="allEllipsis_tip">你可以点击右上角切换班级或联系客服咨询哦</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- 右下角弹窗 -->
        <div class="fixed_btn">
            <div class="contactkf" @click="ClickKf">
                <img src="@/assets/contactkf.png" alt="">
            </div>
            <div class="diss" @click="ClickDiss">
                <img src="@/assets/diss.png" alt="">
            </div>
        </div>

        <!-- 联系客服弹窗 -->
        <Kfmodal ref="Click_kf"/>
        <!-- 我要吐槽弹窗 -->
        <Dissmodal ref="Click_Diss" :title="Diss_title"/>
        <footer class="footer">
            <!-- 页脚 -->
            <iCopyright />
        </footer>
    </div>
</template>

<script>
// 页头
import pageHEAD from "@/components/head"
// 页脚
import iCopyright from "@/components/footer"
// 联系客服弹窗
import Kfmodal from "@/components/kfmodal"
// 我要吐槽弹窗
import Dissmodal from "@/components/Dissmodal"
import { GetLiveList, TotalSchedule, CourseDetail, CoursePapers} from "@/api/Home";
export default {
    name: "Home",
    data() {
        return {
            // 选中的班级id
            courseId: "",
            token: localStorage.token,
            isallEllipsis: false,
            // 分页
            btnnum: 0,
            tabs: ["直播课", "直播预告"],
            count: "",
            // 直播课列表
            LiveList: [
                {
                    extendData:{
                        state:''
                    }
                }
            ],
            // 直播预告列表
            PreviewList: [],
            live_Loading: false,
            // 上次播放
            lastStudy: [],
            // 科目内容列表
            subjectList: [],
            subjectindex: 0,
            // 科目章节列表
            chapterList: [],
            chapterindex: 0,
            // 科目课节列表
            lessonsList:[],
            lessonsindex: 0,
            lessons_Loading: false,
            // 模块图片列表
            moduleimages:[
                {
                    imgUrl: require("@/assets/seatwork.png")
                },
                {
                    imgUrl: require("@/assets/Moldtestpaper.png")
                }
            ],
            Moduleindex: 0,
            // 试卷列表
            testlist: [],
            test_Loading: false,
            // 分页
            pagetotal: 0,
            testpage: 1,
            // 待做模考试卷数
            toDoMockPaperNum: 0,
            //直播数
            liveNum: 0,
            //课程数
            lessonNum: 0,
            //试卷数
            paperNum: 0,
            Diss_title: "课程页面",
            // 最近回放地址
            RecentPlay: "",
            // 最近预告封面
            PreviewImgData: "",
            // videoInfo: "",
            // 视频总时长
            ViodeoDuration: 0,
            // 视频播放暂停
            isplay: true,
            // 是否静音
            ismute: true,
            // 直播数据
            LiveDetails: '',
            // 已直播时间
            Livetime: '',
            // 页面
            fromType: 'Home',
            // 顶部默认Tab
            activeBtn: 0,
            // 顶部Tab列表
            HomeTba: []
        };
    },
    components: {
        pageHEAD,
        iCopyright,
        Kfmodal,
        Dissmodal
    },
    mounted() {
        // 监听滚动条
        window.addEventListener("scroll", this.handleScroll);
    },
    destroyed(){
        window.removeEventListener('scroll', this.handleScroll);//监听页面滚动事件
    },
    methods: {
        // 需要班级id刷新页面
        refresh(){
            this.courseId = localStorage.courseId
            this.GetLiveListData();
            this.GetTotalSchedule();
            this.GetCourseDetail();
            this.GetCoursePapers();
            this.GetPreviewListData();
        },

        // 获取班级详情
        GetCourseDetail(){
            this.HomeTba = []
            CourseDetail(this.courseId).then((res) => {
                if(res.ret == 1){
                    // // console.log(res)
                    // 待做模考试卷数
                    this.toDoMockPaperNum = res.data.toDoMockPaperNum
                    //直播数
                    this.liveNum = res.data.liveNum
                    if(this.liveNum != 0){
                        this.HomeTba.push('直播课程')
                    }
                    // 课程数
                    this.lessonNum = res.data.lessonNum
                    if(this.lessonNum != 0){
                        this.HomeTba.push('总课程表')
                    }
                    // 试卷数
                    this.paperNum = res.data.paperNum
                    if(this.paperNum != 0){
                        this.HomeTba.push('试卷题库')
                    }
                    if(this.liveNum == 0&& this.lessonNum==0&&this.paperNum==0){
                        this.isallEllipsis = true
                    }
                }else{
                    this.isallEllipsis = true
                    console.log(res.msg)
                }
            })
        },

        // 获取直播课列表
        GetLiveListData() {
            this.live_Loading = true
            let Livedata = {
                courseId: this.courseId,
                type: 10
            }
            GetLiveList(Livedata).then((res) => {
                if(res.ret == 1){
                    this.LiveList = res.data
                    // console.log(this.LiveList)
                    if(this.LiveList.length !=0){
                        let playlist = []
                        this.LiveList.map((item,index)=>{
                            if(item.extendData.state == 15){
                                playlist.push(item)
                            }
                        })
                        if(this.LiveList[0].extendData.state == 5){
                            this.RecentPlay = ''
                            this.LiveDetails = this.LiveList[0]
                            this.Liveinit()
                            let startTime = (new Date(this.LiveList[0].startTimeStr.replace(/\./g, '-'))).getTime()
                            // 已直播时间
                            this.Livetime = this.formatSeconds(((new Date().getTime() - startTime) / 1000).toFixed(0))
                        }else{
                            if(playlist.length !=0){
                                this.RecentPlay = playlist[0].extendData
                                this.RecentPlay.lessonTitle = playlist[0].lessonTitle
                                this.RecentPlay.teacherName = playlist[0].teacherName
                                this.RecentPlay.courseLessonId = playlist[0].courseLessonId
                                this.playbackinit()
                            }
                        }
                    }
                }else{
                    console.log(res.msg)
                }
                this.live_Loading = false
            })
            .catch(err => {
                this.live_Loading = false
            });
        },

        // 获取直播预告列表
        GetPreviewListData(){
            this.live_Loading = true
             let Livedata = {
                courseId: this.courseId,
                type: 1
            }
            GetLiveList(Livedata).then((res) => {
                if(res.ret == 1){
                    this.PreviewList = res.data
                    // console.log(this.PreviewList)
                    if(this.PreviewList.length != 0){
                        this.PreviewImgData = this.PreviewList[0]
                    }
                    // console.log(this.PreviewImg)
                }else{
                    console.log(res.msg)
                }
                this.live_Loading = false
            })
            .catch(err => {
                this.live_Loading = false
            });
        },

        //tab切换
		tabchange(e) {
			this.count = e
			this.btnnum = e
            // 获取直播预告列表
            if(e == 1){
                this.GetPreviewListData();
            }else if(e == 0){
                // this.GetLiveListData()
            }
		},

        // 点击直播列表
        ClickLive(data){
            // 判断是否已登录,没有则弹出登录窗口
            if(!this.token){
                this.$refs.pageHead.ClickLogin()
                return
            }
            if(data.extendData.jumpType == 1 || data.extendData.jumpType == 5){
                // 直播回放未生成，进入直播页面
                let { href } = this.$router.resolve({path: '/LiveCourse',query: {webUrl: data.extendData.webUrl,courseLessonId: data.courseLessonId,lessonTitle: data.lessonTitle,teacherName: data.teacherName}})
                window.open(href, '_blank')
            }else if(data.extendData.jumpType == 10){
                // 直播回放
                let { href } = this.$router.resolve({path: '/PlaybackCourse',query: {playBackUrl: data.extendData.playBackUrl,courseLessonId: data.courseLessonId,lessonTitle: data.lessonTitle,teacherName: data.teacherName}})
                window.open(href, '_blank')
            }
        },
        
        // 点击直播预告
        ClickPreview(){
            // 判断是否已登录,没有则弹出登录窗口
            if(!this.token){
                this.$refs.pageHead.ClickLogin()
                return
            }
            this.$Message.warning('直播还未开始');
        },

        // 点击进入直播间
        Tolive(num){
            // 判断是否已登录,没有则弹出登录窗口
            if(!this.token){
                this.$refs.pageHead.ClickLogin()
                return
            }
            if(num == 1){
                // 直播
                let { href } = this.$router.resolve({path: '/LiveCourse',query: {webUrl: this.LiveList[0].extendData.webUrl,courseLessonId: this.LiveList[0].courseLessonId,lessonTitle: this.LiveList[0].lessonTitle,teacherName: this.LiveList[0].teacherName}})
                window.open(href, '_blank')
            }else if(num == 2){
                // 直播回放
                let { href } = this.$router.resolve({path: '/PlaybackCourse',query: {playBackUrl: this.RecentPlay.playBackUrl,courseLessonId: this.RecentPlay.courseLessonId,lessonTitle: this.RecentPlay.lessonTitle,teacherName: this.RecentPlay.teacherName}})
                window.open(href, '_blank')
            }
        },

        // 直播播放器初始化
        Liveinit(){
            var eventEmitter = BJY.eventEmitter;
            var store = BJY.store;
            var userRoleMap = { };
            var config = BJY.config;
            var mediaData = BJY.data.media;
            const auth = BJY.auth;
            var extension = typeof window.cefQuery === 'function' ? BJY.cef : BJY.Player.flash;
            // 监听初始化事件
            eventEmitter
            .one(
                eventEmitter.CLASSROOM_CONNECT_TRIGGER,
                function (event, data) {
                    // 初始化扩展
                    extension.init().then(function () {
                        eventEmitter.trigger(
                            eventEmitter.CLASSROOM_CONNECT_TRIGGER,
                            data
                        );
                    });
                    return false;
                }
            )
            .one(
                eventEmitter.VIEW_RENDER_TRIGGER,
                function (event, data) {
                     var isWebRTCMix = auth.isWebRTC();

                    isWebRTCMix && (store.get('webrtcMixUser').name = '老师');
                    this.player && this.player.destroy();
                    this.player = null;
                    const user = auth.isPresenter()
                      ? store.get("presenter")
                      : isWebRTCMix
                      ? store.get("webrtcMixUser")
                      : store.get("presenter") || store.get("teacher");
                    const options = {
                      element: $("#live-self-player"),
                      replace: false,
                      user: user,
                      extension: BJY.getExtension(),
                      canSwitchCamera: auth.isSelf(store.get("teacher.id")),
                      canSwitchMic: auth.isSelf(store.get("teacher.id")),
                      footerOptions: {
                        onToggleClick: this.pptVideoSwitch,
                      },
                    };
                    !auth.isTeacher() && (options.isWebRTCMix = isWebRTCMix);
                    this.player = BJY.DefaultThemePlayer.create(options);
                    // 文档白板
                    BJY.Whiteboard.create({
                        element: $('#whiteboard .placeholder'),
                        fit: 1, // 1 自适应  2 撑满容器
                    });
                }
            );

            let temp2 = this.LiveDetails.extendData.webUrl.split('?')[1];
            var pram2 = new URLSearchParams('?'+temp2);
            BJY.init({
                env: 'production',
                // 在百家云后台账户中心可查看是否有专属域名
                // 例如专属域名为：zhuanye.at.baijiayun.com, 则域名前缀为：zhuanye
                // 如无专属域名，此项参数不传
                privateDomainPrefix: this.LiveDetails.extendData.partnerIdE,
                // 签名算法请参考 http://dev.baijiayun.com/default/wiki/detail/1中的签名规则选项
                sign: pram2.get('sign'),
                class: {
                    id: this.LiveDetails.extendData.classRoomId,
                    webrtcType: 1
                },
                // 当前进入教室的用户的信息 必须
                // 如果是老师进教室，传老师的信息
                // 如果是学生进教室，传学生的信息
                // number 必须确保是唯一的，如果不唯一，后进的用户会踢掉先进的用户
                user: {
                    number: pram2.get('user_number'),
                    avatar: pram2.get('user_avatar'),
                    name: pram2.get('user_name'),
                    role: pram2.get('user_role'),
                    type: 0
                },
            });
        },

        // 回放播放器初始化
        playbackinit(){
            let temp2 = this.RecentPlay.playBackUrl.split('?')[1];
            var pram2 = new URLSearchParams('?'+temp2);
            BJY.playback
            .init({
                env: 'production',
                privateDomainPrefix: this.RecentPlay.partnerIdE,
                token: pram2.get('token'),
                class: {
                  id: this.RecentPlay.classRoomId,
                  sessionId: ''
                },
                useEncrypt: false,
                user: {
                  number: '0',
                  avatar:
                    '',
                  name: 'default',
                  type: 0
                },
                noNeedMessageList: false,  // 如果您不需要聊天列表，传true。这能减少许多不必要的开销，在聊天数据较多时效果尤为明显
                noNeedUserList: false,  // 如果您不需要用户列表，传true。这能减少许多不必要的开销，在用户数据较多时效果尤为明显
                ignoreBeforeRecordMessage: false, // 是否过滤开始录制之前的聊天消息，默认开始录制之前的聊天消息会显示
            })
            .then((data) => {
              BJY.playback.start()
            //   this.videoInfo = data
              this.playback_whiteboard()
              this.playback(data)
            //   this.qualityList = data.definition
            //   this.currentQuality = data.defaultDefinition
            })
        },

        // 回放白板初始化
        playback_whiteboard(){
            // 使用新白板 (jssdk < 1.13.0 请使用BJY.newWhiteboard, >=1.13.0请使用BJY.Whiteboard)
            BJY.Whiteboard.create({
                element: $('.bjy-playback-whiteboard-wrapper')
            });
        },

        // 回放播放器
        playback(data){
            var video = this.video = document.getElementById('bjy-player-teacher');
            if(data && data.videos){
                let quality = quality || data.defaultDefinition;
                let url = data.videos[quality][0].url;
                url = url.replace("https:", "").replace("http:", "");

                if (url.indexOf("ev1") > -1 && window.flvjs) {
                  // 使用flvjs 播放加密格式视频
                  const player = window.flvjs.createPlayer(
                    {
                      type: "ev1",
                      url: url,
                      cors: true,
                    },
                    {
                      stashInitialSize: 1024,
                    }
                  );
                  player.attachMediaElement(this.videoEl);
                  player.load();
                } else {
                  video.src = url;
                }
            }
            // 播放
            video.addEventListener('play', function () {
                BJY.playback.play();
            });
            // 暂停
            video.addEventListener('pause', function () {
                BJY.playback.pause();
            });
            video.addEventListener('seeked', function() {
                BJY.playback.seek(video.currentTime)
                BJY.playback.play()
            })

            // video播放时时间更新
            video.addEventListener('timeupdate', function (e) {
                BJY.playback.timeupdate(video.currentTime);
            });
            video.addEventListener('durationchange',()=>{
                this.$emit('getDuration')
            })
            setTimeout(() => {
                this.ViodeoDuration = this.formatSeconds(video.duration)
            }, 1000);
        },

        // 秒转时分秒
        formatSeconds(value) {
            let result = parseInt(value)
            let h = Math.floor(result / 3600) < 10 ? Math.floor(result / 3600) : Math.floor(result / 3600);
            let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
            let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
            let res = '';
            if(h !== '00') res += `${h}小时`;
            if(m !== '00') res += `${m}分`;
            // res += `${s}秒`;
            return res;
        },

        // 播放/暂停
        ClickPlay(){
            this.isplay = !this.isplay
            var video = this.video = document.getElementById('bjy-player-teacher');
            if(video.paused){
                // 播放
                video.play();
            }else{
                // 暂停
                video.pause();
            }
        },

        // 点击音量
        ClickMute(){
            this.ismute = !this.ismute
            var video = this.video = document.getElementById('bjy-player-teacher');
            video.muted=!video.muted;
        },

        // 点击最近播放
        ClicklastStudy(){
            if(this.lastStudy.typePlay == 1&&this.lastStudy.extendData.state == 5){
                // 直播
                let { href } = this.$router.resolve({path: '/LiveCourse',query: {webUrl: this.lastStudy.extendData.webUrl,courseLessonId: this.lastStudy.courseLessonId,lessonTitle: this.lastStudy.lessonTitle,teacherName: this.lastStudy.teacherName}})
                window.open(href, '_blank')
            }else if(this.lastStudy.typePlay == 1&&this.lastStudy.extendData.state == 10){
                // 直播回放未生成
                let { href } = this.$router.resolve({path: '/LiveCourse',query: {webUrl: this.lastStudy.extendData.webUrl,courseLessonId: this.lastStudy.courseLessonId,lessonTitle: this.lastStudy.lessonTitle,teacherName: this.lastStudy.teacherName}})
                window.open(href, '_blank')
            }else if(this.lastStudy.typePlay == 1&&this.lastStudy.extendData.state == 15){
                // 直播回放
                let { href } = this.$router.resolve({path: '/PlaybackCourse',query: {playBackUrl: this.lastStudy.extendData.playBackUrl,courseLessonId: this.lastStudy.courseLessonId,lessonTitle: this.lastStudy.lessonTitle,teacherName: this.lastStudy.teacherName}})
                window.open(href, '_blank')
            }else if(this.lastStudy.typePlay == 3){
                // 视频课程
                let { href } = this.$router.resolve({path: '/VideoCourse',query: {id: this.lastStudy.courseLessonId}})
                window.open(href, '_blank')
            }
        },

        // 获取总课程表
        GetTotalSchedule(){
            this.lessons_Loading = true
            TotalSchedule(this.courseId).then((res) => {
                if(res.ret == 1){
                    // console.log(res)
                    // 上次播放
                    if(res.data.lastStudy == null){
                        this.lastStudy = []
                    }else{
                        this.lastStudy = res.data.lastStudy
                    }
                    // 科目内容列表
                    this.subjectList = res.data.subjectList
                    // 科目章节列表
                    this.chapterList = res.data.subjectList[0].chapters
                    this.lessonsList = res.data.subjectList[0].chapters[0].lessons
                }else{
                    console.log(res.msg)
                }
                this.lessons_Loading = false
            })
            .catch(err => {
                this.lessons_Loading = false
            });
        },

        // 点击科目
        subjectschange(item,index){
            this.subjectindex = index
            this.chapterList = item
            this.chapterindex = 0
            this.lessonsList = this.chapterList[0].lessons
        },

        // 点击章节
        chapterchange(item,index){
            this.chapterindex = index
            this.lessonsList = item
        },

        // 点击课节
        lessonschange(item,index){
            // 判断是否已登录,没有则弹出登录窗口
            if(!this.token){
                this.$refs.pageHead.ClickLogin()
                return
            }
            if(item.typePlay == 1&&item.extendData.state == 1){
                this.$Message.warning('直播还未开始');
            }else if(item.typePlay == 1&&item.extendData.state == 5){
                // 直播
                let { href } = this.$router.resolve({path: '/LiveCourse',query: {webUrl: item.extendData.webUrl,courseLessonId: item.courseLessonId,lessonTitle: item.lessonTitle,teacherName: item.teacherName}})
                window.open(href, '_blank')
            }else if(item.typePlay == 1&&item.extendData.state == 10){
                // 直播回放未生成
                let { href } = this.$router.resolve({path: '/LiveCourse',query: {webUrl: item.extendData.webUrl,courseLessonId: item.courseLessonId,lessonTitle: item.lessonTitle,teacherName: item.teacherName}})
                window.open(href, '_blank')
            }else if(item.typePlay == 1&&item.extendData.state == 15){
                // 直播回放
                let { href } = this.$router.resolve({path: '/PlaybackCourse',query: {playBackUrl: item.extendData.playBackUrl,courseLessonId: item.courseLessonId,lessonTitle: item.lessonTitle,teacherName: item.teacherName}})
                window.open(href, '_blank')
            }else if(item.typePlay == 3){
                // 视频课程
                let { href } = this.$router.resolve({path: '/VideoCourse',query: {id: item.courseLessonId}})
                window.open(href, '_blank')
            }
        },

        // 点击模块
        ClickModule(index){
            this.Moduleindex = index
            this.testpage = 1
            this.GetCoursePapers()
        },

        // 获取班级试卷列表
        GetCoursePapers(){
            // moduleType :模块类型，1：模块练习，5：课堂作业，10：随心一练，15：模考试卷
            let Papersdata = {
                courseId: this.courseId,
                moduleType: "5",
                page: this.testpage,
                limit: "9"
            }
            if(this.Moduleindex ==0){
                Papersdata.moduleType = "5"
            }else if(this.Moduleindex ==1){
                Papersdata.moduleType = "15"
            }
            this.test_Loading = true
            CoursePapers(Papersdata).then((res)=>{
                if(res.ret == 1){
                    // console.log(res)
                    this.testlist = res.data
                    this.pagetotal = res.total
                }else{
                    console.log(res.msg)
                }
                this.test_Loading = false
            })
            .catch(err => {
                this.test_Loading = false
            });
        },

        // 点击试卷
        ClickPapers(data){
            // 判断是否已登录,没有则弹出登录窗口
            if(!this.token){
                this.$refs.pageHead.ClickLogin()
                return
            }
            this.$router.push({path: '/QuestionPapers',query: {paperId: data.paperId,belongId: data.belongId,belongType: data.belongType,state: data.state,userPaperId: data.userPaperId}})
        },

        // 分页处理
        currentChange(page){
            this.testpage = page
            this.GetCoursePapers()
        },

        // 点击我要吐槽
        ClickDiss(){
            // 判断是否已登录,没有则弹出登录窗口
            if(!this.token){
                this.$refs.pageHead.ClickLogin()
                return
            }
            this.$refs.Click_Diss.ClickDissmodal()
        },

        // 点击联系客服
        ClickKf(){
            this.$refs.Click_kf.Click_kf()
        },

        // 顶部Tab锚点跳转
        goAnchor(selector,index){
            let el = document.getElementsByClassName(selector)[0]
            this.$nextTick(function () {
	            window.scrollTo({'behavior': 'smooth', 'top': el.offsetTop-80})
	        })
        },

        // 监听滚动条
        handleScroll() {
            let el1 = document.getElementsByClassName('anchor-0')[0]
            let el2 = document.getElementsByClassName('anchor-1')[0]
            let el3 = document.getElementsByClassName('anchor-2')[0]
            let scrollTop =window.pageYOffset ||document.documentElement.scrollTop ||document.body.scrollTop;
            // 到对应的位置选中对应的顶部Tab
            if(this.HomeTba.length == 2){
                if(el2){
                    if(scrollTop < 200){
                        this.activeBtn = 0
                    }else if(scrollTop > el2.offsetTop-300){
                        this.activeBtn = 1
                    }
                }else{
                    if(scrollTop < 200){
                        this.activeBtn = 0
                    }else if(scrollTop > el3.offsetTop-300){
                        this.activeBtn = 1
                    }
                }
            }else if(this.HomeTba.length == 3){
                if (scrollTop < 200) {
                    this.activeBtn = 0
                } else if (scrollTop > el2.offsetTop-100 && scrollTop < el3.offsetTop-300) {
                    this.activeBtn = 1
                } else if (scrollTop > el3.offsetTop-300) {
                    this.activeBtn = 2
            }
            }
        }
    },
};
</script>

<style lang="less" scoped>
    .Home{
        display: flex;
        flex-flow: column;
        min-height: 100vh;
        background-color: #f8f8f8;
        .middle{
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            min-width: 1200px;
            .content{
                width: 1200px;
                margin-top: 80px;
                // 全部内容
                .allcontent{
                    text-align: left;
                    // 直播课程
                    .LiveCourseS{
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 20px;
                        .LiveCourseS_content{
                            width: 806px;
                            height: 628px;
                            background: #FFFFFF;
                            border: 1px solid #EEEEEE;
                            border-radius: 8px;
                            .LiveCourseS_top{
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding-right: 10px;
                                .liveLabel{
                                    width: 190px;
                                    height: 80px;
                                }
                                // 上次播放
                                .lastStudy{
                                    display:inline-block;
                                    .LastTime{
                                        height: 50px;
                                        background: #F4F8FF;
                                        border-radius: 25px;
                                        display: flex;
                                        align-items: center;
                                        padding: 0 20px;
                                        img{
                                            width: 28px;
                                            height: 28px;
                                            margin-right: 20px;
                                        }
                                        .LastTime_tit{
                                            font-size: 14px;
                                            font-weight: 400;
                                            color: #000000;
                                            margin-right: 30px;
                                        }
                                        .LastTime_btn{
                                            width: 90px;
                                            height: 30px;
                                        }
                                    }
                                }
                            }
                            .Live_content{
                                padding: 13px 20px 20px;
                            }
                            .player{
                                font-size: 14px;
                                font-weight: 400;
                                color: #999999;
                                position: relative;
                                .playerContent{
                                    width: 766px;
                                    height: 450px;
                                    position: relative;
                                    border: 1px solid #e9e9e9;
                                }
                                iframe{
                                    width: 100%;
                                    height: 100%;
                                }
                                img{
                                    width: 100%;
                                    height: 100%;
                                }
                                .playmask{
                                    width: 766px;
                                    height: 450px;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    z-index: 10;
                                }
                                .player_lessonTitle{
                                    margin-top: 10px;
                                    width: 100%;
                                    font-size: 20px;
                                    font-weight: 400;
                                    color: #000000;
                                }
                                .player_startTimeStr{
                                    margin-top: 5px;
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    .playIcon{
                                        cursor: pointer;
                                        margin-right: 5px;
                                    }
                                }
                                .video{
                                    width: 100%;
                                    height: 100%;
                                }
                                .liveroom{
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                    width: 210px;
                                    // height: 51px;
                                    padding: 12px 0;
                                    // line-height: 50px;
                                    border: 2px solid #FB7878;
                                    border-radius: 8px;
                                    font-size: 22px;
                                    font-weight: 400;
                                    color: #D13F3F;
                                    text-align: center;
                                    cursor: pointer;
                                    z-index: 10;
                                }
                                .liveroom:hover{
                                    background: #FB7878;
                                    border: 2px solid #FB7878;
                                    color: #FFFFFF;
                                }
                                video::-webkit-media-controls-enclosure { 
                                    /*禁用播放器控制栏的样式*/ 
                                    display: none !important;
                                } 
                                .bjy-player-teacher{
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    z-index: 9;
                                    width: 25%;
                                    height: 25%;
                                }
                                .bjy-playback-whiteboard-wrapper{
                                    width: 766px;
                                    height: 450px;
                                    overflow: auto;
                                }
                                /deep/.bjy-whiteboard{
                                    width: 766px;
                                    height: 450px;
                                }
                                .whiteboard{
                                    width: 766px;
                                    height: 450px;
                                    overflow: auto;
                                }
                            }
                        }
                        .LiveCourseS_list{
                            width: 380px;
                            height: 628px;
                            position: relative;
                            background: #FFFFFF;
                            border: 1px solid #EEEEEE;
                            border-radius: 8px;
                            //分页
                            .tabs {
                            	display: flex;
                                justify-content: space-between;
                            	align-items: flex-end;
                                border-bottom: 1px solid #EEEEEE;
                            	.tab {
                                    height: 45px;
                            		font-size: 16px;
                                    font-weight: 400;
                                    color: #010101;
                                    text-align: center;
                                    line-height: 45px;
                                    position: relative;
                            		.tab1 {
                                        width: 190px;
                            		}
                                    .tabline{
                                        position: absolute;
                                        left: 50%;
                                        transform: translateX(-50%);
                                        width: 24px;
                                        height: 3px;
                                        background: #FB7878;
                                        z-index: 1;
                                    }
                            	}
                            	.active {
                                    color: #D13F3F;
                            	}
                            }
                            .Live_list{
                                background-color: #fff;
                                padding: 0px 15px 0px 10px;
                                height: 576px;
                                overflow: hidden;
                                overflow-y: auto;
                                .Live_Ellipsis{
                                    text-align: center;
                                    img{
                                        margin: 69px 0 18px 0;
                                    }
                                    p{
                                        font-size: 14px;
                                        font-weight: 400;
                                        color: #999999;
                                    }
                                }
                                .Live_list_item{
                                    background: #FFFFFF;
                                    border-radius: 6px;
                                    padding: 20px 0px 20px 4px;
                                    position: relative;
                                    cursor: pointer;
                                    border-bottom: 1px solid #eee;
                                    .Live_list_item_details{
                                        display: flex;
                                        justify-content: space-between;
                                    }
                                    .Live_list_item_left{
                                        width: 140px;
                                        height: 79px;
                                        position: relative;
                                        img{
                                            width: 100%;
                                            height: 100%;
                                            border-radius: 5px;
                                        }
                                        .shade{
                                            width: 100%;
                                            height: 100%;
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            background: #000000;
                                            opacity: 0.6;
                                            border-radius: 6px;
                                            text-align: center;
                                            padding: 10px;
                                            img{
                                                width: 60px;
                                                height: 60px;
                                            }
                                        }
                                    }
                                    .Live_list_item_right{
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: space-between;
                                        width: 187px;
                                        text-align: left;
                                        font-size: 14px;
                                        font-weight: 400;
                                        color: #000000;
                                        .hotLessonTitle{
                                            color: #D13F3F;
                                        }
                                        .Live_list_item_bottom{
                                            p{
                                                font-size: 12px;
                                                color: #999999;
                                            }
                                            .item_time{
                                                display: flex;
                                                justify-content: space-between;
                                                align-items: center;
                                                font-size: 10px;
                                                .hot{
                                                    color: #D13F3F;
                                                }
                                                .playback{
                                                    width: 65px;
                                                    height: 24px;
                                                    line-height: 24px;
                                                    text-align: center;
                                                    background: #EEEEEE;
                                                    border-radius: 0px 4px 4px 4px;
                                                    color: #666;
                                                }
                                                .advance{
                                                    width: 65px;
                                                    height: 24px;
                                                    line-height: 24px;
                                                    text-align: center;
                                                    background: #FFE4E4;
                                                    border-radius: 0px 4px 4px 4px;
                                                    color: #E30000;
                                                }
                                                .ifplay{
                                                    width: 16px;
                                                    height: 16px;
                                                }
                                            }
                                        }
                                    }
                                }
                                .Live_list_item:last-child{
                                    margin-bottom: 0;
                                }
                            }
                            .Live_list::-webkit-scrollbar {
                              width: 5px;
                              height: 1px;
                            }
                            .Live_list::-webkit-scrollbar-thumb {
                                border-radius: 10px;
                                 background-color: #c1c1c1;
                            }
                            .Live_list::-webkit-scrollbar-track {
                              box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                              background: #ededed;
                              border-radius: 10px;
                            }
                        }
                    }

                    // 总课程表
                    .TotalSchedule{
                        margin-bottom: 30px;
                        background: #FFFFFF;
                        border: 1px solid #EEEEEE;
                        border-radius: 8px;
                        .TotalSchedule_tit{
                            img{
                                width: 190px;
                                height: 80px;
                            }
                        }
                        .TotalSchedule_content{
                            position: relative;
                            .subjects_tabs{
                                display: flex;
                                // align-items: flex-end;
                                border-bottom: 1px solid #eee;
                                padding: 0 28px;
                                .tab {
                                    font-size: 18px;
                                    font-weight: 400;
                                    color: #333333;
                                    margin-right: 40px;
                                    position: relative;
                                    height: 37px;
                                }
                                .tabline{
                                    position: absolute;
                                    bottom: 0;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    width: 24px;
                                    height: 3px;
                                    background: #FB7878;
                                }
                                .tab:hover{
                                    color: #D13F3F;
                                }
                                .tab:last-child{
                                    margin-right: 0;
                                }
                                .active {
                                    color: #D13F3F;
                                }
                            }
                            // 章节
                            .allchapter{
                                padding: 18px 40px 0;
                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                border-radius: 2px;
                                .chapter_item{
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #333333;
                                    margin-right: 40px;
                                    position: relative;
                                }
                                .chapter_item:hover{
                                    color: #D13F3F;
                                }
                                .active {
                                    background-color: #FB7878;
                                    color: #FFFFFF;
                                    padding: 6px;
                                    border-radius: 4px;
                                    margin-right: 30px;
                                }
                                .active:hover{
                                    color: #FFFFFF;
                                }
                                .tabline{
                                    width: 0;
                                    height: 0;
                                    border-width: 0 15px 15px;
                                    border-style: solid;
                                    border-color: transparent transparent #F4F8FF;
                                    position: absolute;
                                    bottom: -15px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                }
                            }
                            .alllessons{
                                margin: 15px 20px 25px;
                                padding: 10px 20px 20px;
                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                background: #F4F8FF;
                                border-radius: 8px;
                                .lessons_item{
                                    width: 272px;
                                    background: #FFFFFF;
                                    border-radius: 8px;
                                    margin-right: 10px;
                                    margin-top: 10px;
                                    .lessons_item_details{
                                        height: 152px;
                                        padding: 23px 20px 20px;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: space-between;
                                        color: #000;
                                        font-size: 16px;
                                    }
                                    .lessons_item_top{
                                        .information{
                                            margin-top: 5px;
                                            font-size: 14px;
                                            font-weight: 400;
                                            color: #666666;
                                            .teacherName{
                                                margin-right: 10px;
                                            }
                                            .item_Icon{
                                                font-size: 16px;
                                            }
                                        }
                                    }
                                    .lessons_item_bottom{
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        .lessons_item_bottom_left{
                                            display: flex;
                                            align-items: center;
                                            font-size: 12px;
                                            color: #FFFFFF;
                                            .videoTag{
                                                padding: 4px 6px;
                                                background-color: #6790FF;
                                                border-radius: 0px 4px 4px 4px;
                                            }
                                            .liveIngTag{
                                                width: 60px;
                                                height: 24px;
                                                line-height: 24px;
                                                background: #FB7878;
                                                border-radius: 0px 4px 4px 4px;
                                                text-align: center;
                                                margin-right: 12px;
                                            }
                                            .liveTag{
                                                padding: 4px 6px;
                                                background-color: #FFE4E4;
                                                color: #E30000;
                                                border-radius: 0px 4px 4px 4px;
                                            }
                                            .ifplay{
                                                width: 16px;
                                                height: 16px;
                                            }
                                        }
                                        .alreadyTag{
                                            font-size: 12px;
                                            padding: 4px 6px;
                                            background-color: #FFF2CF;
                                            color: #FF9600;
                                            border-radius: 0px 4px 4px 4px;
                                        }
                                        .lastTag{
                                            font-size: 12px;
                                            padding: 4px 6px;
                                            background-color: #FFB900;
                                            color: #FFFFFF;
                                            border-radius: 0px 4px 4px 4px;
                                        }
                                    }
                                }
                                .lessons_item:nth-child(4n){
                                    margin-right: 0;
                                }
                                .hot{
                                    background: url("../assets/hotplay.png") no-repeat;
                                }
                            }
                            .Lessons_Ellipsis{
                                margin: 65px 0 16px;
                                text-align: center;
                                p{
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #999999;
                                }
                            }
                        }
                    }

                    // 题库试卷
                    .QuestionTest{
                        margin-bottom: 43px;
                        background: #FFFFFF;
                        border: 1px solid #EEEEEE;
                        border-radius: 8px;
                        .QuestionTest_tit{
                            img{
                                width: 190px;
                                height: 80px;
                            }
                        }
                        .QuestionTest_content{
                            padding: 0 20px 20px;
                            display: flex;
                            .QuestionTest_left{
                                .module{
                                    transition: all 0.2s ease-in-out;
                                    margin-bottom: 20px;
                                    width: 238px;
                                    height: 180px;
                                    cursor: pointer;
                                    position: relative;
                                    display: flex;
                                    align-items: center;
                                    .checked{
                                        position: absolute;
                                        top: -8px;
                                        right: -8px;
                                        display: none;
                                    }
                                    .active{
                                        display: block;
                                    }
                                    .tabline{
                                        width: 0;
                                        height: 0;
                                        border-top: 20px solid transparent;
                                        border-right: 15px solid #F4F8FF;
                                        border-bottom: 20px solid transparent;
                                    }
                                    .undone{
                                        font-size: 12px;
                                        font-weight: 400;
                                        color: #FF6E6E;
                                        background: #FFFFFF;
                                        border-radius: 2px;
                                        position: absolute;
                                        top: 45px;
                                        left: 18px;
                                        padding: 1px 3px;
                                    }
                                }
                                .module:hover{
                                    box-shadow: 0px 12px 20px 5px rgba(224, 224, 224, 0.42);
                                    margin-top: -10px;
                                    margin-bottom: 30px;
                                }
                                .module:last-child{
                                    margin-bottom: 0px;
                                }
                                .module:last-child:hover{
                                    margin-bottom: 10px;
                                }

                            }
                            .QuestionTest_right{
                                width: 910px;
                                // height: 517px;
                                background-color: #F4F8FF;
                                border-radius: 8px;
                                margin-left: 12px;
                                padding: 20px 18px 10px;
                                position: relative;
                                .Test_Ellipsis{
                                    text-align: center;
                                    padding-top: 82px;
                                    color: #999999;
                                }
                                .alltestlist{
                                    height: 100%;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;
                                    align-items: center;
                                    .testlist{
                                        display: flex;
                                        flex-wrap: wrap;
                                        align-items: center;
                                        width: 100%;
                                        .test_item{
                                            width: 278px;
                                            cursor: pointer;
                                            background: #FFFFFF;
                                            border-radius: 8px;
                                            margin-right: 19px;
                                            margin-bottom: 20px;
                                            position: relative;
                                            .item_state{
                                                position: absolute;
                                                top: 19px;
                                                left: 0;
                                                width: 4px;
                                                height: 18px;
                                                border-radius: 2px;
                                            }
                                            .Practice_tests{
                                                height: 131px;
                                                padding: 16px;
                                                display: flex;
                                                flex-direction: column;
                                                justify-content: space-between;
                                                .Practice_tests_top{
                                                    display: flex;
                                                    justify-content: space-between;
                                                    span{
                                                        font-size: 16px;
                                                        font-weight: 400;
                                                        color: #000000;                       
                                                        overflow: hidden;
                                                        text-overflow: ellipsis;
                                                        display: -webkit-box;
                                                        -webkit-line-clamp: 2;
                                                        -webkit-box-orient: vertical;
                                                    }
                                                    .Practice_tit{
                                                        width: 142px;
                                                    }
                                                }
                                                .Practice_tests_bottom{
                                                    .doexercise{
                                                        display: flex;
                                                        align-items: center;
                                                        font-size: 12px;
                                                        font-weight: 400;
                                                        color: #000000;
                                                        img{
                                                            margin-right: 6px;
                                                        }
                                                        .everytime{
                                                            color: #999999;
                                                        }
                                                    }
                                                    .progress{
                                                        width: 100%;
                                                        height: 6px;
                                                        background-color: #EEEEEE;
                                                        border-radius: 3px;
                                                        margin-top: 10px;
                                                        .complete{
                                                            height: 100%;
                                                            background: #FB7878;
                                                            border-radius: 3px;
                                                        }
                                                        .succeed{
                                                            height: 100%;
                                                            background: #FFB900;
                                                            border-radius: 3px;
                                                        }
                                                    }
                                                }
                                                .tests_bottom{
                                                    display: flex;
                                                    justify-content: space-between;
                                                    .tests_bottom_left{
                                                        display: flex;
                                                        align-items: center;
                                                        font-size: 12px;
                                                        font-weight: 400;
                                                        color: #000000;
                                                        img{
                                                            margin-right: 5px;
                                                        }
                                                    }
                                                }
                                                .mock_exam_top{
                                                    p{
                                                        font-size: 16px;
                                                        font-weight: 400;
                                                        color: #000000;                       
                                                        overflow: hidden;
                                                        text-overflow: ellipsis;
                                                        display: -webkit-box;
                                                        -webkit-line-clamp: 2;
                                                        -webkit-box-orient: vertical;
                                                    }
                                                    .details{
                                                        margin-top: 8px;
                                                        display: flex;
                                                        align-items: center;
                                                        img{
                                                            margin-right: 4px;
                                                        }
                                                        span{
                                                            margin-right: 10px;
                                                            font-size: 12px;
                                                            font-weight: 400;
                                                            color: #999999;
                                                        }
                                                    }
                                                }
                                                .mock_exam_bottom{
                                                    text-align: right;
                                                }
                                            }
                                        }
                                        .test_item:nth-child(3n){
                                            margin-right: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                //完全缺省
                .allEllipsis{
                    margin-top: 100px;
                    margin-bottom: 180px;
                    img{
                        width: 514px;
                        height: 330px;
                    }
                    .allEllipsis_tit{
                        font-size: 18px;
                        font-weight: 400;
                        color: #333333;
                    }
                    .allEllipsis_tip{
                        margin-top: 10px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #999999;
                    }
                }
            }
        }
    }
    .wrap{
        flex: 1;
    }

    // 右下角按钮
    .fixed_btn{
        position: fixed;
        right: 40px;
        bottom: 92px;
        img{
            width: 80px;
            height: 80px;
        }
    }

    // 加载中
    .demo-spin-icon-load{
        animation: ani-demo-spin 1s linear infinite;
    }
    @keyframes ani-demo-spin {
        from { transform: rotate(0deg);}
        50%  { transform: rotate(180deg);}
        to   { transform: rotate(360deg);}
    }
    .demo-spin-col{
        height: 100px;
        position: relative;
        border: 1px solid #eee;
    }
</style>
